import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth-store";
const SideMenu = () => import("../layouts/side-menu/Main.vue");
const Page1 = () => import("../views/page-1/Main.vue");
const DevSettings = () => import("../views/page-dev/Main.vue");
const Login = () => import("../views/login/Main.vue");
const Profile = () => import("../views/profile/Main.vue");
const PageUsers = () => import("../views/page-users/Main.vue");
const UserForm = () => import("../views/page-user-form/Main.vue");
import auth from "@/services/auth";
const ErrorPage = () => import("../views/error-page/Main.vue");
const Tabs = () => import("../views/page-data/Main.vue");
const EditTab = () => import("../views/add-data/Main.vue");
const EditCategory = () => import("../views/add-category/Main.vue");
const EditArticle = () => import("../views/add-article/Main.vue");
const EditLink = () => import("../views/add-link/Main.vue");
const Articles = () => import("../views/page-articles/Main.vue");
const EditArticle2 = () => import("../views/add-article2/Main.vue");

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Page1,
      },
      {
        path: "tabs",
        name: "Tabs",
        component: Tabs,
      },
      {
        path: "articles",
        name: "Articles",
        component: Articles,
      },
      {
        path: "add-tab/:id?",
        name: "Tab Form",
        component: EditTab,
      },
      {
        path: "add-category/:tabId/:id?",
        name: "Category Form",
        component: EditCategory,
      },
      {
        path: "add-article/:categoryId/:id?",
        name: "Article Form",
        component: EditArticle,
      },
      {
        path: "article/:id?",
        name: "Article Form2",
        component: EditArticle2,
      },
      {
        path: "add-link/:articleId/:id?",
        name: "Link Form",
        component: EditLink,
      },
      {
        path: "dev-page",
        name: "DEV",
        component: DevSettings,
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "users",
        name: "Benutzer",
        component: PageUsers,
      },
      {
        path: "user-form",
        name: "Nutzer Bearbeiten",
        component: UserForm,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  const loggedIn = await auth.verify();
  const authStore = useAuthStore();

  if (loggedIn && to.name == "login") {
    return { name: "Dashboard" };
  }

  if (loggedIn) {
    return true;
  }

  if (to.name === "login") {
    return true;
  }
  authStore.returnUrl = to.fullPath;
  return { name: "login" };
});

export default router;
