{
  "login": "Anmelden",
  "lost-password": "Passwort vergessen?",
  "contact": "Kontaktinformationen",
  "user": "Benutzer",
  "add-user": "Nutzer hinzufügen",
  "delete": "Löschen",
  "general-information": "Allgemeine Informationen",
  "description": "Beschreibung",
  "name": "Name",
  "media": "Medien",
  "image": "Bild",
  "upload": "Dateien hier ablegen oder zum Hochladen anklicken.",
  "video": "Video",
  "edit": "Bearbeiten",
  "save": "Speichern",
  "error": "Etwas ist schief gelaufen!",
  "successfully-saved": "Erfolgreich gespeichert",
  "sure": "Sind Sie sicher?",
  "cancele": "Abbrechen",
  "sure-delete-1": "Wollen Sie diese Datensätze wirklich löschen?",
  "sure-delete-2": "Dieser Vorgang kann nicht rückgängig gemacht werden.",
  "help": "Hilfe",
  "sorting": "Sortierung",
  "date": "Datum der Veröffentlichung",
  "users": "Benutzer",
  "completed": "Abgeschlossen",
  "search": "Suchen...",
  "oops404": "Ups. Diese Seite ist verschwunden.",
  "404": "Vielleicht haben Sie sich bei der Adresse vertippt oder die Seite ist nicht mehr vorhanden.",
  "404_btn": "Zurück zu Startseite",
  "document": "Dokument",
  "title": "Titel",
  "time": "Zeit",
  "createdAt": "Erstellt am",
  "updatedAt": "Aktualisiert am",
  "entries_displyed": "Es werden { pageStart } bis { pageEnd } von { totoal } Einträgen angezeigt",
  "public": "Öffentlich",
  "delete_warning": "Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?",
  "confirm": "Bestätigen",
  "username": "Benutzername",
  "email": "E-Mail",
  "tabs": "Tabs",
  "add-tab": "Tab hinzufügen",
  "add": "Hinzufügen",
  "categorys": "Kategorien",
  "articles": "Artikel",
  "links": "Links",
  "url": "URL",
  "video-url": "Video URL",
  "infoPageText": "Info Seiten Text",
  "book_page": "Buch Seite",
  "add-article": "Artikel hinzufügen",
  "article": "Artikel",
  "premium": "Premium",
  "order": "Reihenfolge",
  "edit_article": "Artikel bearbeiten",
  "add_existing": "Existierende hinzufügen",
  "category": "Kategorie",
  "counter_disabled": "Counter deaktiviert"
}
